<template>
  <div>
    <h2>{{ $t('404 Header') }}</h2>
    <p>{{ $t('404 Description') }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

  @Component
export default class PageNotFound extends Vue {
}
</script>
