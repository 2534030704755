import '@/ts/entry.ts'
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import PageNotFound from '@/components/PageNotFound.vue'
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuelidate from 'vuelidate'
// @ts-ignore
import VueCryptojs from 'vue-cryptojs'
import { KeycloakService } from '@/services/KeycloakService'
import { ConfigFileService } from '@/services/ConfigFileService'

Vue.use(VueRouter)

// Sylius RetoureRevocation
const ReturnForm = () => import(/* webpackChunkName: "sylius" */ './components/ReturnForm.vue')
const WvkRetoureServiceForm = () => import(/* webpackChunkName: "sylius" */ './components/WvkRetoureServiceForm.vue')

// Robomäher
const WelcomeView = () => import(/* webpackChunkName: "robomower" */ './components/robo/WelcomeView.vue')
const SummaryView = () => import(/* webpackChunkName: "robomower" */ './components/robo/SummaryView.vue')
const ErrorView = () => import(/* webpackChunkName: "robomower" */ './components/robo/ErrorView.vue')
const ServiceInfoLightView = () => import(/* webpackChunkName: "robomower" */ './components/robo/light/ServiceInfoLightView.vue')
const ContactInfoLightView = () => import(/* webpackChunkName: "robomower" */ './components/robo/light/ContactInfoLightView.vue')
const ServiceInfoClassicView = () => import(/* webpackChunkName: "robomower" */ './components/robo/classic/ServiceInfoClassicView.vue')
const ContactInfoClassicView = () => import(/* webpackChunkName: "robomower" */ './components/robo/classic/ContactInfoClassicView.vue')
const ProductInfoInstallationView = () => import(/* webpackChunkName: "robomower" */ './components/robo/installation/ProductInfoInstallationView.vue')
const ServiceInfoInstallationView = () => import(/* webpackChunkName: "robomower" */ './components/robo/installation/ServiceInfoInstallationView.vue')
const ContactInfoInstallationView = () => import(/* webpackChunkName: "robomower" */ './components/robo/installation/ContactInfoInstallationView.vue')
const ProductInfoWireRepairView = () => import(/* webpackChunkName: "robomower" */ './components/robo/wireRepair/ProductInfoWireRepairView.vue')
const ContactInfoWireRepairView = () => import(/* webpackChunkName: "robomower" */ './components/robo/wireRepair/ContactInfoWireRepairView.vue')

// Servicecenter
const ArticleIdentificationView = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/ArticleIdentificationView.vue')
const FailureDamageTypeView = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/FailureDamageTypeView.vue')
const ContactView = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/ContactView.vue')
const ServicePartnerView = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/ServicePartnerView.vue')
const ServiceCenterSummaryView = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/ServiceCenterSummaryView.vue')
const RetoureDashboard = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/RetoureDashboard.vue')
const RetoureDetail = () => import(/* webpackChunkName: "servicecenter" */ './components/ServiceCenter/RetoureDetail.vue')

const routes = [
    { path: '/retoure', component: WvkRetoureServiceForm },
    { path: '/revocation', component: ReturnForm },
    { path: '/robomower/:type/welcome', name: 'robomowerWelcome', component: WelcomeView },
    { path: '/robomower/:type/summary', name: 'robomowerSummary', component: SummaryView },
    { path: '/robomower/light/service', name: 'robomowerLightService', component: ServiceInfoLightView },
    { path: '/robomower/light/contact', name: 'robomowerLightContact', component: ContactInfoLightView },
    { path: '/robomower/classic/service', name: 'robomowerClassicService', component: ServiceInfoClassicView },
    { path: '/robomower/classic/contact', name: 'robomowerClassicContact', component: ContactInfoClassicView },
    {
        path: '/robomower/installation/product',
        name: 'robomowerInstallationProduct',
        component: ProductInfoInstallationView
    },
    {
        path: '/robomower/installation/service',
        name: 'robomowerInstallationService',
        component: ServiceInfoInstallationView
    },
    {
        path: '/robomower/installation/contact',
        name: 'robomowerInstallationContact',
        component: ContactInfoInstallationView
    },
    { path: '/robomower/wire/product', name: 'robomowerWireRepairProduct', component: ProductInfoWireRepairView },
    { path: '/robomower/wire/contact', name: 'robomowerWireRepairContact', component: ContactInfoWireRepairView },
    { path: '/robomower/error', name: 'robomowerError', component: ErrorView },
    { path: '/servicecenter/:type/identify', redirect: { name: 'serviceCenteridentifyArticle' } },
    { path: '/servicecenter/identify', name: 'serviceCenteridentifyArticle', component: ArticleIdentificationView },
    {
        path: '/servicecenter/:id/failure-damage',
        name: 'serviceCenterfailureDamageAnalysis',
        component: FailureDamageTypeView
    },
    { path: '/servicecenter/:id/contact', name: 'serviceCenterContact', component: ContactView },
    { path: '/servicecenter/:id/summary', name: 'serviceCenterSummary', component: ServiceCenterSummaryView },
    { path: '/servicecenter/service-partner', name: 'serviceCenterPartner', component: ServicePartnerView },
    { path: '/servicecenter/dashboard', name: 'serviceCenterDashboard', component: RetoureDashboard },
    { path: '/servicecenter/retoure/:id/detail', name: 'serviceCenterRetoureDetail', component: RetoureDetail },
    { path: '*', name: 'pageNotFound', component: PageNotFound }
]

const router = new VueRouter({
    routes
})

// @ts-ignore
let lang = window.vueAppLang
// @ts-ignore
let webLocaleCode = window.vueAppLocaleCode
// @ts-ignore
let webshopType = window.webshop
// @ts-ignore
let keycloakRealm = window.realm
// @ts-ignore
let customerCurrency = window.currency

Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(VueCryptojs)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC8VOCaj14x_gfTq3JWyThkd1_6-_8c2sw',
        libraries: 'places'
    }
})

const packagesLink = {
    'de': '/de_DE/page/verpackungshinweise',
    'en': '/en_DE/page/packaging-guideline',
    'en_GB': '/en_GB/page/packaging-guideline',
    'es': '/es_ES/page/directrices-de-embalaj',
    'fr': '/fr_FR/page/guide-demballage',
    'it': '/it_IT/page/linee-guida-per-l-imballaggio',
    'pt': '/pt_PT/page/orientacoes-quanto-as-embalagens',
    'nl': '/nl_NL/page/verpakkings-instructies'
}
const webshopUrl = (!webshopType) ? 'https://www.einhell-service.com' : ''

if (!webLocaleCode) {
    if (lang){
        webLocaleCode = lang
    } else {
        webLocaleCode = 'en_DE'
    }
}

if (!keycloakRealm) {
    keycloakRealm = 'master'
}

const origin = webshopType ?? "web"

if (!webshopType) {
    webshopType = 'esi'
}
if (!customerCurrency) {
    customerCurrency = 'EUR'
}

if (!lang) {
    if (navigator.language) {
        lang = navigator.language.substring(0, 2)
    } else {
        lang = 'de'
    }
}
// @ts-ignore
import en from '@/lang/en.json'
// @ts-ignore
import de from '@/lang/de.json'

export const webshop = webshopType
export const locale = lang
export const localeCode = webLocaleCode
export const authRealm = keycloakRealm
export const currency = customerCurrency
// @ts-ignore
export const packagingGuidelinesUrl = webshopUrl + packagesLink[lang]

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: {
        'de_DE_isc': ['de'],
        'default': ['en']
    },
    messages: { en: en, de: de }
})

function loadLocaleMessage (locale: string, fallback: boolean = false) {
    import(
        `@/lang/${locale.replace('-', '_')}.json`
        ).then((message) => {
        message = JSON.parse(JSON.stringify(message))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        i18n.setLocaleMessage(locale, message)
        i18n.locale = locale
        console.log(locale)
    })
        .catch((error) => {
            if (!fallback) {
                const splitedLocale = locale.split('_')
                splitedLocale.pop()
                const fallBackLocale = splitedLocale.join('_')
                loadLocaleMessage(fallBackLocale, fallBackLocale.length <= 2)
            } else {
                console.log(locale + ' language couldn\'t loaded: ' + error)
            }
        })
}

loadLocaleMessage(webLocaleCode + '_' + webshopType)

// @ts-ignore
window.ccCountry = ''

const kcLocale = webLocaleCode.replace('_', '-')
const initOptions = {
    'locale': kcLocale,
    'origin': origin,
    'loginRequired': true
}

ConfigFileService.init()
    .then(async () => {
        KeycloakService.init(initOptions)
            .then(() => {
                new Vue({
                    i18n,
                    router,
                    render: h => h(App)
                }).$mount('#app')
            })
            .catch()
    })
    .catch(reason => {
        console.log('Error while Start Application: ', reason)
    })

