import axios from "axios";

type Config = {
    VUE_APP_INCLUDE_CSS: boolean,
    VUE_APP_AFTER_SALES_URL: string
    VUE_APP_OAUTH_URL: string
    VUE_APP_ITEM_REPO_URL: string
    VUE_APP_SERIAL_NUMBER_URL: string
}

class InternalConfigFileService {
    private _includeCss: boolean | null = null
    private _aftersalesUrl: string | null = null
    private _keycloakUrl: string | null = null
    private _itemrepoUrl: string | null = null
    private _serialnumberUrl: string | null = null

    async init(): Promise<void> {
        const applicationEnv = (await axios.get<Config>(__webpack_public_path__ + 'settings.json')).data
        if (applicationEnv) {
            this._includeCss = applicationEnv.VUE_APP_INCLUDE_CSS
            this._aftersalesUrl = applicationEnv.VUE_APP_AFTER_SALES_URL
            this._keycloakUrl = applicationEnv.VUE_APP_OAUTH_URL
            this._itemrepoUrl = applicationEnv.VUE_APP_ITEM_REPO_URL
            this._serialnumberUrl = applicationEnv.VUE_APP_SERIAL_NUMBER_URL
        } else {
            throw new Error('No settings file: Could not init application')
        }

    }

    get includeCss(): boolean {
        if (this._includeCss) {
            return this._includeCss
        } else {
            return false
        }
    }

    get aftersalesUrl(): string {
        if (this._aftersalesUrl) {
            return this._aftersalesUrl
        } else {
            throw new Error('Aftersales URL Error: Could not init application')
        }
    }

    get keycloakUrl(): string {
        if (this._keycloakUrl) {
            return this._keycloakUrl
        } else {
            throw new Error('Keycloak URL Error: Could not init application')
        }
    }
}

export const ConfigFileService = new InternalConfigFileService()
