<template>
  <div class="vue-content">
    <div class="page-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { ConfigFileService } from '@/services/ConfigFileService'

@Component
export default class Dashboard extends Vue {
  beforeMount () {
    if (process.env.NODE_ENV == "development" && ConfigFileService.includeCss === true) {
      // @ts-ignore
      import('@/assets/app.css')
    }
  }
}
</script>
