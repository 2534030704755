// @ts-ignore
// eslint-disable-next-line no-unused-vars
import Keycloak, { KeycloakInstance } from 'keycloak-js'
import { ConfigFileService } from '@/services/ConfigFileService'

type initOptions = {
  url: string
  realm: string
  clientId: string
  onLoad: 'login-required'|'check-sso'
}

class InternalKeycloakService {
  private _keycloak: KeycloakInstance | null = null
  private _user: any | null = null
  private _locale: string | null = null
  private _origin: string | null = 'web'
  private _loginRequired: string | null = null

  private _sessionTimeoutChecker: number | null = null

  async init (init:any) {
    this._locale = init.locale
    this._origin = init.origin
    this._loginRequired = init.loginRequired
    // @ts-ignore
    const keycloakRealm = window.realm || 'master'
    const oauthDomain = ConfigFileService.keycloakUrl

    const initOptions: initOptions = {
      url: oauthDomain,
      realm: keycloakRealm,
      clientId: 'service_component',
      onLoad: 'check-sso'
    }

    const keycloak = Keycloak(initOptions)
    this._keycloak = keycloak

    // init Keycloak
    const authenticated = await keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false })

    if (authenticated) {
      const profile = await keycloak.loadUserProfile()
      const userinfo = await keycloak.loadUserInfo()

      if (profile && userinfo) {
        if (profile.username && profile.email) {
          this._user = {
            ...profile,
            ...userinfo
          }
          this.refreshToken()
        } else {
          // Todo: Add Error Handling
          throw Error('User Profile missing. Please Contact Admin')
        }
      }
    } else {
      if (this._loginRequired) {
        await this.login()
      }
    }
  }

  async login () {
    if (this._keycloak) {
      window.location.href = this._keycloak.createLoginUrl({ redirectUri: window.location.href }) + '&kc_locale=' + this._locale + "&origin=" + this._origin
    }
  }

  async logout () {
    if (this._keycloak) {
      return this._keycloak.logout()
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  async refreshToken (){
    if (this._keycloak) {
      if (this._keycloak.isTokenExpired(60)) {
        await this._keycloak.updateToken(60)
        if (this._sessionTimeoutChecker) {
          clearTimeout(this._sessionTimeoutChecker)
        }
        this.refreshToken()
      } else {
        this._sessionTimeoutChecker = window.setTimeout(() => {
          this.refreshToken()
        }, 30000)
      }
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  // Getters
  /**
   * Returns the token as string for the Authorization header.
   */
  get token (): string {
    if (this._keycloak) {
      if (this._keycloak.isTokenExpired(60)) {
        this._keycloak.updateToken(60)
      }
      return `${this._keycloak.token}`
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  get authenticated (): boolean {
    if (this._keycloak) {
      return this._keycloak.authenticated
        ? this._keycloak.authenticated
        : false
    } else {
      return false
    }
  }

  get userName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.username || ''
        : ''
    } else {
      return ''
    }
  }

  get fullName (): string {
    return this.firstName + ' ' + this.lastName
  }

  get firstName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.firstName || ''
        : ''
    } else {
      return ''
    }
  }

  get lastName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.lastName || ''
        : ''
    } else {
      return ''
    }
  }

  get subject (): string {
    if (this._keycloak) {
      return this._user.sub
        ? this._user.sub || ''
        : ''
    } else {
      return ''
    }
  }
}

export const KeycloakService = new InternalKeycloakService()
